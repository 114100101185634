.footer {
  background-color: var(--color-light-dark);
  color: var(--color-white);
  .footer_box {
    padding: 60px 20px;
    padding-bottom: 0px;
    a {
      color: var(--color-white);
    }
    .sections {
      display: grid;
      grid-template-columns: 50% 30% 20%;
      gap: 40px;

      .footer_head {
        display: flex;
        h2 {
          text-transform: uppercase;
          margin-bottom: 30px;
          margin-left: 10px;
        }
      }
      .footer_head::before {
        content: "";
        width: 8px;
        height: 25px;
        background-color: var(--color-red);
        color: var(--color-red);
      }
      .content {
        p {
          width: 70%;
          margin-bottom: 20px;
          line-height: 25px;
        }
        .footer_social a {
          margin-right: 10px;
          margin-bottom: 50px;

          .fa-facebook {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(12, 99, 180);
          }
          .fa-twitter {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(18, 150, 190);
            margin: 0px 10px;
          }
          .fa-whatsapp {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(16, 189, 88);
          }
        }
        ul {
          line-height: 30px;
          a {
            width: 60%;
          }

          .fa-solid {
            margin-right: 20px;
            color: var(--color-red);
          }
        }
      }
      .section_3 .content {
        display: flex;
        flex-direction: column;
        line-height: 30px;
      }
    }
  }
  .lower_footer {
    background-color: var(--color-dark);
    padding: 20px 0px;
    p {
      margin-left: 20px;
      a {
        color: var(--color-red);
      }
    }
  }
}
/*======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  .footer {
    .footer_box {
      .sections {
        grid-template-columns: 60% 40%;
        .content {
          ul {
            a {
              width: 100%;
            }
          }
        }
      }
    }
    .lower_footer {
      margin-top: 30px;
    }
  }
}
/*======= MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .footer {
    .footer_box {
      .sections {
        grid-template-columns: 100%;
        .content {
          p {
            width: 100%;
          }
           ul {
            a {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
