.nav {
  box-shadow: 1px 3px 3px -2px #b4b4b4;
  padding: 10px 0px;
  background-color: var(--color-white);
}
.nav .nav_ul a {
  margin-right: 30px;
  text-transform: uppercase;
  font-weight: 600;
}
.nav .nav_links {
  color: var(--color-ash);
}
.nav .nav_links:hover {
  color: var(--color-primary);
}
.nav .menuIcon {
  margin-top: -20px;
  display: none;
}
/*======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  .nav .nav_ul a {
    display: none;
  }
  .nav .menuIcon {
    display: block;
  }
}
