.slick-slider .slider_box {
  height: 500px;
  width: 100%;
}
.slick-slider .left img {
  object-fit: cover;
  position: relative;
  z-index: -1;
  width: 100%;
  height: 500px;
}
.slick-slider .left .light_dark {
  background: rgba(0, 0, 0, 0.1);
  height: 500px;
  width: 100%;
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
}
