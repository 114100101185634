.blog_box {
  .post_details {
    p {
      line-height: 25px;
    }
    h1,
    h3 {
      font-weight: 100;
      padding: 30px 0px;
    }
    i {
      color: rgb(141, 141, 141);
      margin-right: 10px;
    }

    .post_image {
      img {
        width: 100%;
      }
    }
    .header {
      .date_by {
        padding: 30px 0px;
      }
    }
    .lower_details {
      margin-top: 30px;
    }
    .prev_next {
      margin-top: 10px;
      ul {
        li {
          i {
            font-size: 12px;
          }
          margin-top: 10px;
        }
      }
    }
  }
  .comment {
    margin-top: 20px;
    .comment_form {
      .header {
        h1 {
          font-weight: 100;
        }
      }
      .form_input {
        margin-top: 30px;
        .textarea {
          .text_box {
            width: 100%;
            height: 250px;
            padding: 10px;
          }
        }
        .input_box {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          input {
            padding: 10px;
            width: 40%;
            height: 40px;
            margin-bottom: 10px;
          }
        }
      }
      .comment_btn {
        a {
          color: var(--color-white);
          text-decoration: none;
        }
      }
    }
  }
}
/*======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  .blog_box {
    .comment {
      .comment_form {
        .form_input {
          .input_box {
            input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

