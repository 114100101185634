.track_result {
  .track {
    .table_box {
      min-height: 400px;
      width: 100%;
      margin: 60px 0px;
      .table_content {
        width: 100%;
        .table {
          border: 2px solid var(--color-bg-btn);
          width: 50%;
          .table_header h3 {
            text-align: center;
            padding: 10px 0px;
          }
          .table_grid {
            display: grid;
            grid-template-columns: 70% 30%;
            align-items: center;
          }
          .table_rows {
            border-top: 2px solid var(--color-bg-btn);
            padding: 2px 0px;
            .table_data {
              input {
                width: 97%;
                padding: 10px;
                margin: 0px 2px;
                font-size: 16px;
                background-color: rgb(228, 234, 240);
              }

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 43px;
                border-left: 2px solid rgba(0, 0, 0, 0.1);

                position: relative;
                top: 39px;
                margin-top: -44px;
                right: 8px;
                z-index: -1;
              }

              button {
                padding: 10px 20px;
                text-transform: uppercase;
                background-color: var(--color-ash);
                color: var(--color-white);
                border-radius: 4px;
                cursor: pointer;
              }
            }
          }
          .bottom {
            padding: 10px 0px;
            padding-left: 2px;
            color: var(--color-ash);
            font-weight: 600;
          }
        }
      }
    }
  }
}
.shipping_content {
  width: auto;
  ::-webkit-scrollbar {
    display: block;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #6c6c6c;
  }
  margin-top: 30px;
  .track_table {
    background: transparent;
    .table {
      background: transparent;
    }
    h2 {
      color: #6c6c6c;
    }
    .tableBtn {
      background-color: #e94560;
      color: #fff;
      font-weight: 600;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

/*======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  .track_result {
    .track {
      .table_box {
        height: 400px;
        width: 100%;
        .table_content {
          width: 100%;
          .table {
            width: 100%;
          }
        }
      }
    }
  }
}
/*======= MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .track_result {
    .track {
      .table_box {
        padding: 0px 20px;
        .table_content {
          .table {
            // width: 100%;
            .table_data {
              input {
                // width: 100%;
                width: 90% !important;
                font-size: 14px;
              }
              span {
                position: relative;
                left: -18px;
              }
              button {
                width: 100%;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 50px;
                position: relative;
                right: 10px;
                font-size: 12px;
              }
            }
            .table_rows {
              .table_grid {
                grid-template-columns: 60% 40%;
              }
            }
          }
        }
      }
    }
  }
}
