.blog_grid {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 20px;
  margin: 20px 0px;
}
/*======= MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .blog_grid {
    grid-template-columns: 100%;
    // padding: 0px 20px;
  }
}
