/*====Blog Side Bar ====*/
.side_bar {
  .sections {
    .section_box {
      background-color: var(--color-white);
      padding: 30px 30px;
      margin-bottom: 20px;
      h2,
      h4 {
        font-weight: 100;
      }
      .search {
        .search_box_btn {
          input {
            width: 80%;
            background-color: var(--color-bg-input);
            padding: 9px 10px;
            font-size: 16px;
          }
          button {
            padding: 10px;
            margin-left: 10px;
            cursor: pointer;
            border: 1px solid var(--color-light);
          }
        }
      }
      .category_list {
        li {
          margin-bottom: 10px;
          a {
            color: var(--color-red);
            text-decoration: underline;
            margin-right: 5px;
          }
        }
      }
      .recent_post {
        li {
          margin-bottom: 10px;
          a {
            text-decoration: underline;
            color: var(--color-red);
          }
        }
      }
      .archive_list {
        margin-top: 20px;
        li {
          margin-bottom: 10px;
          a {
            text-decoration: underline;
            color: var(--color-red);
          }
        }
      }
      .lower_category {
        margin-top: 20px;
      }
      .event_list {
        margin-top: 20px;
        .event_list_ul {
          li {
            margin-bottom: 15px;
            p {
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}

/*====Side Bar ====*/
.menu_btn_icon {
  .menu_icon {
    color: var(--color-red);
    font-size: 40px;
  }
}
.listItemText {
  color: var(--color-dark);
}

/*======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  .side_modal {
    display: grid;
  }
}

/*======= MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 800px) {
  .side_bar {
    .sections {
      .section_box {
        padding: 20px 10px;
        label {
          margin-left: 10px;
        }
        .search_box_btn {
          flex-wrap: wrap;

          input {
            width: 100%;
            margin-left: 10px;
          }
          button {
            margin-top: 10px;
            width: 80px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

/*======= MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .side_bar {
    .sections {
      .section_box {
        padding: 20px 20px;
        label {
          margin-left: 0px;
        }
        .search_box_btn {
          flex-wrap: nowrap;
          input {
            margin-left: 0;
          }
          button {
            height: 35px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
